<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../helpers/errorHandler';
import userService from '../../../helpers/userService';

/**
 * Starter component
 */
export default {
  page: {
    title: "Homework",
  },
  data() {
    return {
      title: "Homework",
      items: [
        {
          text: "Work",
          href: "/home",
        },
        {
          text: "Homeworks",
          href: "/quizes-log",
        },
        {
          text: "Quiz",
          active: true,
        },
      ],
      form:{
        quiz_id: this.$route.params.quiz,
        answers:[],
      },
      questions:[],
      baseUrl:null,
      loading:false
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods:{
    async handleSubmit() {
      this.loading = true;
        // requests for the create
        if(this.form.answers.findIndex(element => element.answer == "") >= 0){
          errorHandler.methods.errorValue('Please answer all questions!')
          this.loading= false;
          return;
        }
        await this.$store.dispatch("homework/answerHomework",this.form).then(
            ()=>{
                this.$router.go(-1);
                errorHandler.methods.successful('You have solved the Homework !!');
                this.loading = false;
            }
        ).catch((err)=>{
            errorHandler.methods.errorMessage(err);
            this.$router.go(-1)
            this.loading = false
        });
    },
  },
  async created(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.$store.dispatch('homework/getStudentHomework',this.$route.params.quiz).then( async (res)=>{
        this.questions = res.quiz_questions.sort(function(a, b) {
          return (a.id - b.id);
        });
        await this.questions.forEach(element => {
            this.form.answers.push({
                question_id: element.id,
                answer: ""
            })
        });
    }).catch( err => {
        errorHandler.methods.errorMessage(err);
        this.$router.go(-1)
    });
    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Answer the following questions</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                        id="input-group-2"
                        :label="`Question ${index+1}:`"
                        label-for="input-A"
                        v-for=" question,index in questions"
                        :key="question.id"
                    >
                    <img style="width: 300px; height: 300px;" v-if="question.question_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.question_img}`">
                        <h3 v-html="question.question_text" class="question">
                        </h3>
                        <div class="row">
                            <div class="col-12" v-if="question.ans_one  || question.ans_one_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_one_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_one_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_one"><div v-html="question.ans_one"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_two  || question.ans_two_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_two_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_two_img}`">
                                <b-form-radio  v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_two"><div v-html="question.ans_two"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_three  || question.ans_three_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_three_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_three_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_three"><div v-html="question.ans_three"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_four  || question.ans_four_img"> 
                                <img style="width: 300px; height: 300px;" v-if="question.ans_four_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_four_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_four"><div v-html="question.ans_four"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_five  || question.ans_five_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_five_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_five_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_five"><div v-html="question.ans_five"></div></b-form-radio>
                            </div>
                        </div>
                        <hr>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" :disabled="loading">Submit</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
.question::v-deep img {
  max-height: 320px !important;
  max-width: 320px !important;
}
</style>